import { useEffect, useState } from 'react';
import classNames from 'classnames';
import Vimeo from '@u-wave/react-vimeo';

import Button from '../Button/Button';
import Image from '../Image/Image';
import styles from './HeroStartPage.module.scss';

const HeroStartPage = ({
    title = '',
    text = '',
    links = {},
    image = {},
    vimeoUrl = '',
}) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isTouchDevice, setIsTouchDevice] = useState(false);
    const [mounted, setMounted] = useState(false);
    const [videoStarted, setVideoStarted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleScroll = () => {
        const position = window.scrollY / 3;
        const windowHeight = window.innerHeight;

        if (window.scrollY <= windowHeight) {
            setScrollPosition(position);
        }
    };

    useEffect(() => {
        const isTouchDevice =
            'ontouchstart' in window || navigator.msMaxTouchPoints;

        setIsTouchDevice(isTouchDevice);
    }, []);

    useEffect(() => {
        if (isTouchDevice) {
            return;
        }

        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [isTouchDevice]);

    const style = {
        transform: `translate3d(0px, ${scrollPosition}px, 0px)`,
    };

    const classes = classNames(styles['HeroStartPage'], {
        [styles['HeroStartPage--Video']]: !!vimeoUrl,
        [styles['HeroStartPage--Visible']]: mounted,
        [styles['HeroStartPage--VideoStarted']]: videoStarted,
    });

    return (
        <div className={classes}>
            <div className={styles['HeroStartPage__Container']}>
                <div className={styles['HeroStartPage__Content']}>
                    <h1 className={styles['HeroStartPage__Title']}>{title}</h1>
                    <p className={styles['HeroStartPage__Text']}>{text}</p>
                    {!!links.length && (
                        <div className={styles['HeroStartPage__Button']}>
                            {links.map((link, index) => (
                                <Button
                                    key={index}
                                    modifiers={
                                        index === 0
                                            ? ['hero']
                                            : ['heroSecondary']
                                    }
                                    {...link}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </div>
            <div className={styles['HeroStartPage__MediaContainer']}>
                {!isTouchDevice && mounted && (
                    <figure className={styles['HeroStartPage__VideoContainer']}>
                        <Vimeo
                            className={styles['HeroStartPage__Video']}
                            video={vimeoUrl}
                            muted={true}
                            controls={false}
                            volume={0}
                            autoplay={true}
                            title={''}
                            onPlay={() => setVideoStarted(true)}
                            loop={true}
                            background={true}
                            dnt={true}
                        />
                    </figure>
                )}
                <figure
                    className={styles['HeroStartPage__ImageContainer']}
                    style={!!vimeoUrl || isTouchDevice ? null : style}>
                    <div className={styles['HeroStartPage__Image']}>
                        <Image
                            image={image}
                            loading={'eager'}
                            priority={true}
                            sizes="(max-width: 1200px) 50vw, 33vw"
                        />
                    </div>
                </figure>
            </div>
        </div>
    );
};

HeroStartPage.propTypes = {};

export default HeroStartPage;
